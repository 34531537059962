"use strict";

window.removeAllTinymce = function removeAllTinymce() {
  if (tinymce && typeof tinymce != "undefined") {
    tinymce.remove();
  }
};
//========================================================//
// 1-a. REGENERATE CSRF
//========================================================//
window.regenerateCSRF = function regenerateCSRF() {
  //regenerate the CSRF
  $.ajax({
    type: "POST",
    url: "/ajax/getCSRF",
  }).done(function (data) {
    data = JSON.parse(data);
    $("input[name=" + data.name + "]").val(data.hash);
  });
};

//========================================================//
// 1. ENABLE TINYMCE
//========================================================//

window.enable_tinymce = function enable_tinymce(
  height,
  width,
  selector,
  toolbars,
  max_chars
) {
  let theme = "silver";

  if ($(window).width() <= 400) {
    theme = "mobile";
  }

  if (!toolbars) {
    toolbars =
      "code | blocks | image link | undo redo | styleselect | bold italic underline subscript superscript | alignleft aligncenter alignright alignjustify | table | bullist numlist";
  }

  if (!height) height = "300px";
  if (!width) width = "100%";
  if (!selector) selector = "textarea:not(.notinymce)";
  if (!max_chars) max_chars = "unlimited";

  tinymce.init({
    selector: selector,
    license_key: "gpl",
    max_chars: max_chars,
    setup: function (editor) {
      const target = editor.id;

      if (max_chars != "unlimited" && max_chars > 0) {
        //get the number of characters
        let html_content = "<div>" + $("#" + target).val() + "</div>";
        let text_content = $(html_content).text();
        let limit_left_id = $("#" + target).data("remain");
        let current_left = $("#" + limit_left_id).text() - text_content.length;
        $("#" + limit_left_id).text(current_left);
      }

      let allowedKeys = [8, 37, 38, 39, 40, 46]; // backspace, delete and cursor keys
      editor.on("keydown", function (e) {
        if (allowedKeys.indexOf(e.keyCode) != -1) return true;
        if (
          max_chars != "unlimited" &&
          tinymce_getContentLength() + 1 > max_chars
        ) {
          e.preventDefault();
          e.stopPropagation();
          return false;
        }
        return true;
      });
      editor.on("keyup", function (_e) {
        tinymce_updateCharCounter(this, tinymce_getContentLength(), max_chars);
      });

      editor.on("change", function (_e) {
        editor.save();

        //check if character count needed

        //get the form of the field and set it to changed

        let form_id = $("#" + target)
          .closest("form")
          .attr("id");

        $("#" + form_id).data("changed", true);
      });
    },
    init_instance_callback: function () {
      if (max_chars != "unlimited" && max_chars > 0) {
        // initialize counter div
        $("#" + this.id)
          .next()
          .parent()
          .append(
            '<p class="tinymce_count_container"><span class="char_count">' +
              max_chars +
              "</span> characters remaining</p>"
          );
        tinymce_updateCharCounter(this, tinymce_getContentLength(), max_chars);
      }
    },
    paste_preprocess: function (_plugin, args) {
      let editor = tinymce.get(tinymce.activeEditor.id);

      if (max_chars != "unlimited" && max_chars > 0) {
        let len = editor.contentDocument.body.innerText.length;
        let text = args.content;
        if (len + text.length > max_chars) {
          alert(
            "Pasting this exceeds the maximum allowed number of " +
              max_chars +
              " characters."
          );
          args.content = "";
        } else {
          tinymce_updateCharCounter(editor, len + text.length, max_chars);
        }
      }
    },
    content_css: "/css/frontend.css",
    // theme: theme,
    // skin_url: "/js/tinymce/skins/ui/oxide",
    document_base_url: baseurl,
    menubar: false,
    branding: true,
    height: height,
    width: width,
    object_resizing: true,
    theme_advanced_resize_vertical: true,
    force_br_newlines: true,
    statusbar: true,
    relative_urls: false,
    remove_script_host: false,
    convert_urls: false,
    convert_unsafe_embeds: false,
    sandbox_iframes: false,
    indent: false,
    valid_children: "+body[style]",
    // To load the plugin from the tinymce/plugins directory...
    extended_valid_elements:
      "iframe[*],style,span[*],i[*],div[*],h1[*],hr[*],em[*]",
    paste_auto_cleanup_on_paste: true,
    paste_remove_spans: true,
    paste_as_text: false,
    paste_text_linebreaktype: "combined",
    plugins: ["code", "link", "image", "lists", "advlist", "table"],

    //toolbar1: toolbars,
    toolbar1: toolbars,
    // without images_upload_url set, Upload tab won't show up
    images_upload_url: "/files/upload_mce_image/",
    image_caption: true,

    // override default upload handler to simulate successful upload
    images_upload_handler: tinymce_image_upload_handler,
  });
};

const tinymce_image_upload_handler = (blobInfo, progress) =>
  new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.withCredentials = false;
    xhr.open("POST", "/files/upload_mce_image/");

    let filename = blobInfo.filename();

    let extension = filename.split(".").pop();

    filename =
      filename
        .substr(0, filename.lastIndexOf("."))
        .replace(/[^a-z0-9]/gi, "")
        .toLowerCase() +
      "." +
      extension;

    xhr.upload.onprogress = (e) => {
      progress((e.loaded / e.total) * 100);
    };

    xhr.onload = () => {
      if (xhr.status === 403) {
        reject({ message: "HTTP Error: " + xhr.status, remove: true });
        return;
      }

      if (xhr.status < 200 || xhr.status >= 300) {
        reject("HTTP Error: " + xhr.status);
        return;
      }

      const json = JSON.parse(xhr.responseText);

      if (!json || typeof json.location != "string") {
        reject("Invalid JSON: " + xhr.responseText);
        return;
      }

      resolve(json.location);
    };

    xhr.onerror = () => {
      reject(
        "Image upload failed due to a XHR Transport error. Code: " + xhr.status
      );
    };

    const formData = new FormData();
    formData.append("file", blobInfo.blob(), filename);

    xhr.send(formData);
  });

function tinymce_updateCharCounter(el, len, max_chars) {
  $("#" + el.id)
    .next()
    .parent()
    .find(".char_count")
    .text(max_chars - len);
}

function tinymce_getContentLength() {
  let html_content =
    "<div>" +
    tinymce.get(tinymce.activeEditor.id).getContent({ format: "raw" }) +
    "</div>";
  let text_content = $(html_content).text();

  return text_content.length;
}

//========================================================//
// 2. GET BROWSER WIDTH
//========================================================//
window.getWidth = function getWidth() {
  return Math.max(
    document.body.scrollWidth,
    document.documentElement.scrollWidth,
    document.body.offsetWidth,
    document.documentElement.offsetWidth,
    document.documentElement.clientWidth
  );
};

//========================================================//
// 3. GET BROWSER HEIGHT
//========================================================//
window.getHeight = function getHeight() {
  return Math.max(
    document.body.scrollHeight,
    document.documentElement.scrollHeight,
    document.body.offsetHeight,
    document.documentElement.offsetHeight,
    document.documentElement.clientHeight
  );
};

//========================================================//
// 4. INITIALIZE COLOUR PICKER
//========================================================//
window.init_color_picker = function init_color_picker() {
  if ($(".color_picker").length > 0) {
    $(".color_picker").each(function () {
      let doUpdate = $(this).data("update") ? $(this).data("update") : false;
      let field = $(this).data("field");
      let table = $(this).data("table");
      let id = $(this).data("id");
      let id_name = $(this).data("id-name");
      let type = $(this).data("type") ? $(this).data("type") : "hex";
      let input_id = $(this).attr("id");

      let el = $(this);

      let hex = "";

      el.spectrum({
        flat: false,
        showInput: true,
        allowEmpty: true,
        preferredFormat: type,
        showButtons: true,
        clickoutFiresChange: true,
        change: function (color) {
          if (color) {
            hex = color.toHexString();
          }
          //set the field value
          el.attr("value", hex);
          //if this is an auto update, do it
          if (doUpdate === true) {
            $.ajax({
              type: "POST",
              url: "/ajax/updColour",
              data: {
                table: table,
                id: id,
                id_name: id_name,
                colour: hex,
                field: field,
              },
            }).done(function (result) {
              if (result == 1) {
                //change the background colour of the logo container
                if (input_id == "banner_bg_colour") {
                  $("#logo-box").css("background-color", hex);
                }
              }
            });
          }
        },
        dragstop: function (color) {
          if (color) {
            hex = color.toHexString();
          }
          //set the field value
          el.attr("value", hex);
          //if this is an auto update, do it
          if (doUpdate === true) {
            $.ajax({
              type: "POST",
              url: "/ajax/updColour",
              data: {
                table: table,
                id: id,
                id_name: id_name,
                colour: hex,
                field: field,
              },
            }).done(function (result) {
              if (result == 1) {
                //change the background colour of the logo container
                if (input_id == "banner_bg_colour") {
                  $("#logo-box").css("background-color", hex);
                }
              }
            });
          }
        },
      });
    });
  }
};

//========================================================//
// 5. DOWNLOAD SVG
//========================================================//
window.downloadSVG = function downloadSVG(dataURL, file) {
  let dl = document.createElement("a");
  document.body.appendChild(dl); // This line makes it work in Firefox.
  dl.setAttribute("href", dataURL);
  dl.setAttribute("download", file);
  dl.click();
  dl.remove();
};

//========================================================//
// 6. RESET ITEM ORDERS
//========================================================//
window.reset_order_numbers = function reset_order_numbers(item_class) {
  if (!item_class) item_class = ".author";
  //loop through all instances of the class and update the order number
  if ($(item_class).length > 0) {
    let pos = 1;
    let num_items = $("#reorderItems > " + item_class).length;
    $("#reorderItems > " + item_class).each(function () {
      //update the position number
      $(this).find(".position").text(pos);
      //first remove the disabled class from both arrows
      $(this).find(".reorder").removeClass("disabled");
      //if this is the first position, disable the up arrow
      if (pos == 1) {
        $(this).find(".arrow-up").addClass("disabled");
      }
      //if this is the first position, disable the up arrow
      if (pos == num_items) {
        $(this).find(".arrow-down").addClass("disabled");
      }
      pos++;
    });
  }
};

//========================================================//
// 7. GET NUMBER OF OBJECTS
//========================================================//
window.ObjectLength = function ObjectLength(object) {
  let length = 0;
  for (let key in object) {
    if (object.hasOwnProperty(key)) {
      ++length;
    }
  }
  return length;
};

//========================================================//
// 8. REORDER ITEMS
//========================================================//
window.update_item_orders = function update_item_orders() {
  let idname = $("#reorderItems").data("idname");
  let field = $("#reorderItems").data("field");
  let table = $("#reorderItems").data("table");
  let new_items = [];

  $("#reorderItems div.reorderItem").each(function () {
    new_items.push($(this).attr("data-id"));
  });
  //ajax call to add the asset
  $.ajax({
    type: "POST",
    url: "/ajaxadmin/updateItemOrder/",
    data: { table: table, field: field, idname: idname, new_items: new_items },
  });
};

//========================================================//
// 9. INITIALIZE TIME PICKER
//========================================================//

window.init_time_picker = function init_time_picker() {
  if ($(".timepicker").length > 0) {
    $(".timepicker").timepicker({
      timeFormat: "HH:mm",
      interval: 5,
      minTime: "00:00",
      maxTime: "23:55",
      defaultTime: "",
      startTime: "00:00",
      disableTextInput: true,
      dynamic: false,
      dropdown: true,
      scrollbar: true,
      change: function (time) {
        if ($(this).attr("data-validator") == "datetime-validator") {
          $(this).closest("form").foundation("validateInput", $(this));
        }

        //if this has a timezone-update class we need to fire the call
        if ($(this).hasClass("timezone-update")) {
          let timezone = $(this).data("timezone");
          let date_id = $(this).data("timezone-date");
          let time_ids = $(this).data("timezone-times").split(",");
          populate_timezones(date_id, time_ids, timezone);
        }
      },
    });
  }
};

//========================================================//
// 10. INITIALIZE WORD WRAP
//========================================================//
window.init_word_wrap = function init_word_wrap() {
  if ($(".word-wrap").length > 0) {
    $(".word-wrap").each(function (_index) {
      //set css to wrap text even if no breaking spaces
      $(this).css("display", "inline-block");
      $(this).css("width", "100%");
      $(this).css("overflow-wrap", "break-word");
    });
  }
};

//========================================================//
// 11. INITIALIZE HAMBURGER MENU
//========================================================//
window.init_hamburger = function init_hamburger() {
  $(".hamburger-menu-icon").click(function () {
    $(this).toggleClass("button-open");

    $(".hamburger-menu").each(function () {
      if ($(this).hasClass("hamburger-open") > 0) {
        $(this).removeClass("hamburger-open");
      } else {
        $(this).addClass("hamburger-open");
      }
    });
  });
};

//========================================================//
// 12. INITIALIZE DATE PICKER
//========================================================//
window.init_date_picker = function init_date_picker() {
  if ($(".date").length > 0) {
    $(".date").fdatepicker();
  }

  if ($(".datetime").length > 0) {
    $(".datetime").fdatepicker({
      format: "dd/mm/yyyy hh:ii",
      disableDblClickSelection: true,
      pickTime: true,
    });
  }

  if ($(".date_future").length > 0) {
    init_future_date();
  }
};

//========================================================//
// 13. INITIALIZE FUTURE DATE PICKER
//========================================================//
window.init_future_date = function init_future_date() {
  let nowTemp = new Date();
  let now = new Date(
    nowTemp.getFullYear(),
    nowTemp.getMonth(),
    nowTemp.getDate(),
    0,
    0,
    0,
    0
  );

  $(".date_future")
    .fdatepicker({
      format: "dd/mm/yyyy",
      onRender: function (date) {
        return date.valueOf() < now.valueOf() ? "disabled" : "";
      },
    })
    .data("datepicker");
};

//========================================================//
// 14. GET TODAY
//========================================================//
window.GetTodayDate = function GetTodayDate() {
  let tdate = new Date();
  let dd = tdate.getDate(); //yields day
  if (dd < 10) {
    dd = "0" + dd;
  }
  let MM = tdate.getMonth() + 1; //yields month
  if (MM < 10) {
    MM = "0" + MM;
  }
  let yyyy = tdate.getFullYear(); //yields year
  return yyyy + "-" + MM + "-" + dd;
};

//========================================================//
// 15. SET CLIPBOARD
//========================================================//
window.setClipboard = function setClipboard(value) {
  let tempInput = document.createElement("input");
  tempInput.style = "position: absolute; left: -1000px; top: -1000px";
  tempInput.value = value;
  document.body.appendChild(tempInput);
  tempInput.select();
  /* Select the text field */
  tempInput.select();
  tempInput.setSelectionRange(0, 99999); /* For mobile devices */

  /* Copy the text inside the text field */
  navigator.clipboard.writeText(tempInput.value);

  document.body.removeChild(tempInput);
  $("#modalClickMsg").text("Copied");
  $("#modalClickMsg").css("z-index", 0).animate({ opacity: 1 }, 400);
  setTimeout(function () {
    $("#modalClickMsg").animate({ opacity: 0 }, 400);
  }, 2000);
  setTimeout(function () {
    $("#modalClickMsg").css("z-index", -1);
  }, 2400);
};
//========================================================//
// 16. INITIALIZE MULTISELECT
//========================================================//
window.init_multiselect = function init_multiselect() {
  $(".multi_select").each(function (_index) {
    $(this).multiselect("destroy");
    let id = $(this).attr("id");
    let liveInput = $(this).attr("data-live") ? $(this).attr("data-live") : "";
    let selectAll = $(this).attr("data-select-all")
      ? $(this).attr("data-select-all")
      : false;
    let hasSearch = $(this).attr("data-search")
      ? $(this).attr("data-search")
      : false;
    let showSelected = $(this).attr("data-show")
      ? $(this).attr("data-show")
      : "false";
    let showContainer = $(this).attr("data-show-container")
      ? $(this).attr("data-show-container")
      : "";
    let placeholder = $(this).attr("placeholder");
    if (!placeholder) {
      placeholder = "please select";
    }

    let new_text = "";

    $("#" + id).multiselect({
      texts: { placeholder: placeholder },
      selectAll: selectAll,
      liveInput: "#" + liveInput,
      search: hasSearch,
      onOptionClick: function (_element, option) {
        if (showSelected == "true") {
          let is_checked = option.checked;
          let value = option.title;

          let current_selected = $("#" + showContainer).text();
          let selected_array = current_selected.split(", ");

          //add to the displayed values
          if (is_checked === true) {
            if ($.inArray(value, selected_array) == -1) {
              if (current_selected.length > 0) {
                new_text = current_selected + ", " + value;
              } else {
                new_text = value;
              }
            }
          } else {
            //remove from the displated values
            let index = $.inArray(value, selected_array);
            if (index >= 0) selected_array.splice(index, 1);
            new_text = selected_array.join(", ");
          }
          //show the label if there is some text
          if (new_text) {
            $("#" + showContainer)
              .siblings("label")
              .show();
          } else {
            $("#" + showContainer)
              .siblings("label")
              .hide();
          }
          //set the text
          $("#" + showContainer).text(new_text);
        }
      },
      onLoad: function (_element) {
        if (showSelected == "true") {
          $("#" + id + " option:selected").each(function () {
            new_text += $(this).text() + ", ";
          });

          if (new_text) {
            //remove the 2 characters from the string
            new_text = new_text.slice(0, -2);
          }

          //show the label if there is some text
          if (new_text) {
            $("#" + showContainer)
              .siblings("label")
              .show();
          } else {
            $("#" + showContainer)
              .siblings("label")
              .hide();
          }
          //set the text
          $("#" + showContainer).text(new_text);
        }
      },
    });
  });
};

//========================================================//
// 17. CONFIRM MODAL
//========================================================//
window.confirmModal = function confirmModal(
  title,
  message,
  callbackyes,
  callbackno
) {
  // create your modal template
  let modal =
    '<div class="reveal tiny text-center" id="confirmation">' +
    "<h2>" +
    title +
    "</h2>" +
    '<p class="lead">' +
    message +
    "</p>" +
    '<button class="button float-left alert no" data-close>No</button>' +
    '<button class="button success float-right yes">Yes</button>' +
    "</div>";
  // appending new reveal modal to the page
  $("body").append(modal);
  // registergin this modal DOM as Foundation reveal
  let confirmation = new Foundation.Reveal($("#confirmation"));
  // open
  confirmation.open();
  // listening for yes click

  $("#confirmation")
    .children(".yes")
    .on("click", function () {
      // close and REMOVE FROM DOM to avoid multiple binding
      confirmation.close();
      $("#confirmation").remove();
      // calling the function to process
      callbackyes.call();
    });

  $("#confirmation")
    .children(".no")
    .on("click", function () {
      // close and REMOVE FROM DOM to avoid multiple binding
      confirmation.close();
      $("#confirmation").remove();
      // calling the function to process
      callbackno.call();
    });

  $(document).on("closed.zf.reveal", "#confirmation", function () {
    // remove from dom when closed
    $("#confirmation").remove();
  });
};

//========================================================//
// 18. REDIRECT TO LOGIN
//========================================================//
window.redirectToLogin = function redirectToLogin() {
  $.ajax({
    type: "POST",
    url: "/ajax/getBaseUrl/",
  }).done(function (url) {
    window.location.replace(url + "/login");
  });
};
